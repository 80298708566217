import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Paper,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { ContentCopy, Visibility, CloudUpload } from "@mui/icons-material";
import { CompanyInt } from "../../../typescript/interfaces/CompanyInt";
import "../../../assets/styles/global.css";
import {
  createCompanyPage,
  getCompanyPage,
  updateCompanyPage,
  uploadCompanyPageImageToFirebase,
} from "../../../utils/companyPageFunctions";
import { useNotification } from "../../context/NotificationContext";
import CompanyPageLayout from "./CompanyPageLayout";
import { JobInt } from "../../../typescript/interfaces/JobInterface";

interface CompanyPageBuilderProps {
  company: CompanyInt;
}

interface CompanyPageData {
  companyId: string;
  name: string;
  description: string;
  subdomainId: string;
  logoUrl: string;
  bannerUrl: string;
  datePosted?: Date;
}

const CompanyPageBuilder: React.FC<CompanyPageBuilderProps> = ({ company }) => {
  const { showNotification } = useNotification();
  const demoPositions: JobInt[] = [
    {
      title: "Desarrollador",
      company: company.name,
      number: "001",
      datePosted: new Date(),
      description:
        "Buscamos un desarrollador Full Stack con experiencia en React y Node.js para unirse a nuestro equipo de desarrollo.",
      companyId: company._id as string,
      location: {
        city: "Buenos Aires",
        country: "Argentina",
        latitude: -34.6037,
        longitude: -58.3816,
      },
      inPerson: false,
      fullTime: true,
      recieveViaEmail: false,
      recieveEmail: "",
      status: true,
      editKey: undefined,
      userId: undefined,
      applicants: 0,
      paid: false,
      aiScorePaid: false,
      questions: [],
      salaryRange: [50000, 80000],
      mapResults: undefined,
      applicantStateOptions: [
        {
          id: "default",
          name: "Nuevo",
          color: "#4CAF50",
          order: 0,
          isDefault: true,
        },
      ],
    },
    {
      title: "Gerente",
      company: company.name,
      number: "002",
      datePosted: new Date(),
      description:
        "Únete a nuestro equipo de diseño para crear experiencias de usuario excepcionales y interfaces intuitivas.",
      companyId: company._id as string,
      location: {
        city: "Buenos Aires",
        country: "Argentina",
        latitude: -34.6037,
        longitude: -58.3816,
      },
      inPerson: true,
      fullTime: true,
      recieveViaEmail: false,
      recieveEmail: "",
      status: true,
      editKey: undefined,
      userId: undefined,
      applicants: 0,
      paid: false,
      aiScorePaid: false,
      questions: [],
      salaryRange: [60000, 90000],
      mapResults: undefined,
      applicantStateOptions: [
        {
          id: "default",
          name: "Nuevo",
          color: "#4CAF50",
          order: 0,
          isDefault: true,
        },
      ],
    },
    {
      title: "Analista",
      company: company.name,
      number: "003",
      datePosted: new Date(),
      description:
        "Buscamos un Product Manager experimentado para liderar el desarrollo de nuevos productos y características.",
      companyId: company._id as string,
      location: {
        city: "Buenos Aires",
        country: "Argentina",
        latitude: -34.6037,
        longitude: -58.3816,
      },
      inPerson: true,
      fullTime: true,
      recieveViaEmail: false,
      recieveEmail: "",
      status: true,
      editKey: undefined,
      userId: undefined,
      applicants: 0,
      paid: false,
      aiScorePaid: false,
      questions: [],
      salaryRange: [70000, 100000],
      mapResults: undefined,
      applicantStateOptions: [
        {
          id: "default",
          name: "Nuevo",
          color: "#4CAF50",
          order: 0,
          isDefault: true,
        },
      ],
    },
  ];

  const [pageData, setPageData] = useState<CompanyPageData>({
    companyId: company._id as string,
    name: company.name,
    description: "",
    subdomainId: "",
    logoUrl: "",
    bannerUrl: "",
  });
  const [showPreview, setShowPreview] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [highlightedSubdomain, setHighlightedSubdomain] =
    useState<boolean>(false);
  const [loadingPageData, setLoadingPageData] = useState(true);
  const [previewUrls, setPreviewUrls] = useState<{
    logo?: string;
    banner?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingImages, setUploadingImages] = useState<{
    logo?: boolean;
    banner?: boolean;
  }>({});
  const [existingPage, setExistingPage] = useState<boolean>(false);
  const [originalPageData, setOriginalPageData] =
    useState<CompanyPageData | null>(null);

  const handleImageUpload = (type: "logo" | "banner") => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) return;

      setUploadingImages((prev) => ({ ...prev, [type]: true }));

      // Create preview URL
      const previewUrl = URL.createObjectURL(file);
      setPreviewUrls((prev) => ({
        ...prev,
        [type]: previewUrl,
      }));

      try {
        // upload the image to firebase
        const result = await uploadCompanyPageImageToFirebase(
          file,
          pageData.companyId,
          type
        );
        console.log(result);
      } finally {
        setUploadingImages((prev) => ({ ...prev, [type]: false }));
      }
    };

    input.click();
  };

  // Split into two separate effects
  React.useEffect(() => {
    // Store URLs that need cleanup
    const urlsToCleanup = new Set<string>();

    // Add cleanup function for new preview URLs
    return () => {
      urlsToCleanup.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, []); // Empty dependency array for component unmount

  // Track new preview URLs
  React.useEffect(() => {
    const currentUrls = Object.values(previewUrls).filter(Boolean) as string[];
    return () => {
      // Only cleanup URLs that are no longer in use
      const newUrls = Object.values(previewUrls).filter(Boolean) as string[];
      const removedUrls = currentUrls.filter((url) => !newUrls.includes(url));
      removedUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [previewUrls]); // Track changes to previewUrls

  const handleSave = async () => {
    if (uploadingImages.logo || uploadingImages.banner) {
      showNotification(
        "Por favor espere a que se completen las cargas de imágenes",
        "error"
      );
      return;
    }

    setIsLoading(true);
    try {
      await createCompanyPage(pageData);
      showNotification("Página de empresa creada exitosamente", "success");
    } catch (error: unknown) {
      if (error instanceof Error) {
        showNotification(error.message, "error");
        if (
          error.message ===
          "Ya existe una página de empresa con este subdominio"
        ) {
          setHighlightedSubdomain(true);
        }
      } else {
        showNotification("Error al crear la página de empresa", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyLink = () => {
    const companyPageUrl = `https://quierolaburo.com/empresas/${pageData.subdomainId}`;
    navigator.clipboard.writeText(companyPageUrl);
    setCopySuccess(true);
  };

  const handleSubdomainChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    // Convert to lowercase and replace invalid characters with hyphens
    const sanitizedValue = value
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9-]/g, "-") // Replace invalid chars with hyphen
      .replace(/-+/g, "-") // Replace multiple hyphens with single hyphen
      .replace(/^-+|-+$/g, ""); // Remove hyphens from start and end

    setPageData({ ...pageData, subdomainId: sanitizedValue });
  };

  // Helper function to get the current image URL (either preview or saved)
  const getImageUrl = (type: "logo" | "banner") => {
    return previewUrls[type] || pageData[`${type}Url`];
  };

  // get company page if already exists

  useEffect(() => {
    const getCompanyData = async () => {
      setLoadingPageData(true);
      try {
        const companyPage = await getCompanyPage(
          undefined,
          company._id as string
        );
        if (companyPage) {
          setPageData(companyPage as CompanyPageData);
          setOriginalPageData(companyPage as CompanyPageData);
          setExistingPage(true);
        }
      } catch (error) {
        console.error("Error fetching company page:", error);
      }
      setLoadingPageData(false);
    };
    console.log("getCompanyData");
    getCompanyData();
  }, [company._id]);

  // Add update function placeholder
  const handleUpdate = async () => {
    console.log(pageData);
    setIsLoading(true);
    try {
      // TODO: Implement update functionality
      await updateCompanyPage(pageData.companyId, pageData);
      showNotification("Página de empresa actualizada exitosamente", "success");
    } catch (error) {
      showNotification("Error al actualizar la página de empresa", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Add this helper function to check for changes
  const hasChanges = () => {
    if (!originalPageData) return true;

    return (
      pageData.name !== originalPageData.name ||
      pageData.description !== originalPageData.description ||
      pageData.subdomainId !== originalPageData.subdomainId ||
      pageData.logoUrl !== originalPageData.logoUrl ||
      pageData.bannerUrl !== originalPageData.bannerUrl ||
      previewUrls.logo !== undefined ||
      previewUrls.banner !== undefined
    );
  };

  // Modify the return statement to show loading state
  if (loadingPageData) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 flex items-center justify-center">
        <Paper className="rounded-3xl p-8 bg-white shadow-sm border border-gray-100 flex flex-col items-center space-y-4">
          <CircularProgress style={{ color: "var(--laburo-green)" }} />
          <Typography variant="body1" className="text-gray-600">
            Cargando información de la empresa...
          </Typography>
        </Paper>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <Paper className="mb-6 p-4 bg-white rounded-lg shadow-sm border border-gray-100 relative">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <h1 className="text-2xl font-bold text-gray-900">
              Página de Empresa
            </h1>
            {existingPage && (
              <div className="absolute right-5 top-5 gap-1 px-2 py-1 bg-gray-100 rounded-full text-sm text-gray-600 flex items-center">
                <CloudUpload sx={{ fontSize: 16 }} />
                <span>Publicada</span>
              </div>
            )}
          </div>
        </div>

        {!showPreview ? (
          <div className="space-y-6">
            {/* Updated Image Upload Section */}
            <div className="space-y-4">
              {/* Banner Section */}
              <div className="relative">
                <Typography variant="subtitle2" className="text-gray-700 mb-2">
                  Imagen de banner
                </Typography>
                <div className="h-48 bg-gray-50 rounded-2xl border-2 border-dashed border-gray-300 overflow-hidden relative group">
                  {previewUrls.banner || pageData.bannerUrl ? (
                    <>
                      <img
                        src={previewUrls.banner || pageData.bannerUrl}
                        alt="Banner"
                        className="h-full w-full object-cover"
                      />
                      <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        {uploadingImages.banner ? (
                          <div className="w-6 h-6 border-2 border-laburo-green border-t-transparent rounded-full animate-spin" />
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              setPreviewUrls((prev) => ({
                                ...prev,
                                banner: undefined,
                              }));
                              setPageData((prev) => ({
                                ...prev,
                                bannerUrl: "",
                              }));
                            }}
                            className="min-w-0 w-6 h-6 rounded-full"
                            style={{ backgroundColor: "var(--laburo-green)" }}
                          >
                            ×
                          </Button>
                        )}
                      </div>
                    </>
                  ) : (
                    <Button
                      startIcon={<CloudUpload />}
                      onClick={() => handleImageUpload("banner")}
                      className="absolute inset-0 w-full h-full"
                      style={{ color: "var(--laburo-green)" }}
                    >
                      Subir Banner
                    </Button>
                  )}
                </div>

                {/* Logo Section */}
                <div className="absolute -bottom-6 left-8">
                  <div className="w-24 h-24 rounded-full bg-white p-1 shadow-lg">
                    <div className="w-full h-full bg-gray-50 rounded-full border-2 border-dashed border-gray-300 overflow-hidden relative group">
                      {previewUrls.logo || pageData.logoUrl ? (
                        <>
                          <img
                            src={previewUrls.logo || pageData.logoUrl}
                            alt="Company logo"
                            className="h-full w-full object-cover"
                          />
                          <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                            {uploadingImages.logo ? (
                              <div className="w-6 h-6 border-2 border-laburo-green border-t-transparent rounded-full animate-spin" />
                            ) : (
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  setPreviewUrls((prev) => ({
                                    ...prev,
                                    logo: undefined,
                                  }));
                                  setPageData((prev) => ({
                                    ...prev,
                                    logoUrl: "",
                                  }));
                                }}
                                className="min-w-0 w-6 h-6 rounded-full text-sm"
                                style={{
                                  backgroundColor: "var(--laburo-green)",
                                }}
                              >
                                ×
                              </Button>
                            )}
                          </div>
                        </>
                      ) : (
                        <Button
                          onClick={() => handleImageUpload("logo")}
                          className="absolute inset-0 w-full h-full"
                          style={{ color: "var(--laburo-green)" }}
                        >
                          <CloudUpload />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Company Information Fields */}
            <div className="space-y-6">
              <TextField
                fullWidth
                label="Nombre de la empresa"
                value={pageData.name}
                onChange={(e) =>
                  setPageData({ ...pageData, name: e.target.value })
                }
                className="bg-gray-50 rounded-2xl"
                variant="outlined"
              />

              <TextField
                fullWidth
                label="Descripción de la empresa"
                multiline
                rows={4}
                value={pageData.description}
                onChange={(e) =>
                  setPageData({ ...pageData, description: e.target.value })
                }
                className="bg-gray-50 rounded-2xl"
                variant="outlined"
              />
            </div>

            <div className="space-y-2">
              <Typography variant="subtitle2" className="text-gray-700 mb-1">
                URL de tu página de empresa
              </Typography>
              <div className="flex items-center space-x-2">
                {existingPage ? (
                  <TextField
                    value={`quierolaburo.com/empresas/${pageData.subdomainId}`}
                    className="flex-1"
                    disabled
                    InputProps={{
                      className: "rounded-2xl bg-gray-50 opacity-70",
                    }}
                    variant="outlined"
                  />
                ) : (
                  <TextField
                    value={pageData.subdomainId}
                    onChange={handleSubdomainChange}
                    placeholder="tu-empresa"
                    className="flex-1"
                    error={highlightedSubdomain}
                    InputProps={{
                      startAdornment: (
                        <div className="bg-gray-100 px-3 py-2 text-gray-500">
                          quierolaburo.com/empresas/
                        </div>
                      ),
                      className: "rounded-2xl bg-gray-50",
                    }}
                    variant="outlined"
                  />
                )}
              </div>

              <Typography variant="caption" className="text-gray-500">
                {existingPage
                  ? "El subdominio no puede ser modificado una vez creada la página"
                  : "Esta será la URL pública de tu página de empresa"}
              </Typography>
            </div>

            {/* Action Buttons */}
            <div className="flex gap-4 mt-8">
              <Button
                variant="contained"
                onClick={existingPage ? handleUpdate : handleSave}
                disabled={isLoading || (existingPage && !hasChanges())}
                className={`rounded-full px-6 py-2 text-white hover:bg-laburo-green/90 relative ${
                  existingPage && !hasChanges()
                    ? "bg-gray-400"
                    : "bg-laburo-green"
                }`}
                style={{
                  backgroundColor:
                    existingPage && !hasChanges()
                      ? "#9CA3AF"
                      : "var(--laburo-green)",
                  color: "white",
                  minWidth: "200px",
                }}
              >
                {isLoading ? (
                  <span>
                    {existingPage ? "Actualizando..." : "Creando página..."}
                  </span>
                ) : existingPage ? (
                  "Actualizar Cambios"
                ) : (
                  "Guardar Cambios"
                )}
              </Button>
              <Button
                variant="outlined"
                startIcon={<Visibility />}
                onClick={() => setShowPreview(!showPreview)}
                className="rounded-full px-6 py-2 text-laburo-green border-laburo-green hover:border-laburo-green/90 hover:text-laburo-green/90"
                style={{
                  borderColor: "var(--laburo-green)",
                  color: "var(--laburo-green)",
                }}
              >
                Vista Previa
              </Button>
              <Button
                variant="outlined"
                startIcon={<ContentCopy />}
                onClick={handleCopyLink}
                disabled={!pageData.subdomainId}
                className={`rounded-full px-6 py-2 ${
                  !pageData.subdomainId
                    ? "text-gray-400 border-gray-300"
                    : "text-laburo-green border-laburo-green hover:border-laburo-green/90 hover:text-laburo-green/90"
                }`}
                style={{
                  borderColor: pageData.subdomainId
                    ? "var(--laburo-green)"
                    : undefined,
                  color: pageData.subdomainId
                    ? "var(--laburo-green)"
                    : undefined,
                }}
              >
                Copiar Enlace
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <CompanyPageLayout
              company={company}
              pageData={{
                description: pageData.description,
                bannerUrl: getImageUrl("banner"),
                logoUrl: getImageUrl("logo"),
                name: pageData.name,
              }}
              positions={demoPositions as JobInt[]}
            />
            <Button
              variant="contained"
              onClick={() => setShowPreview(false)}
              className="mt-8 rounded-full px-6 py-2 bg-laburo-green text-white hover:bg-laburo-green/90"
              style={{
                backgroundColor: "var(--laburo-green)",
                color: "white",
              }}
            >
              Volver a Editar
            </Button>
          </div>
        )}
      </Paper>

      <Snackbar
        open={copySuccess}
        autoHideDuration={3000}
        onClose={() => setCopySuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setCopySuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          ¡Enlace copiado al portapapeles!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CompanyPageBuilder;
