import axios from "axios";

const getApplicantActivity = async (applicantId: string) => {
  try {
    const response = await axios.get(
      `https://getapplicantactivity-gi2cautoja-rj.a.run.app?applicantId=${applicantId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching applicant activity:", error);
    throw error;
  }
};

export default getApplicantActivity;
