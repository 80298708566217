import axios from "axios";
import { BusinessUserInt } from "../typescript/interfaces/BusinessUserInt";

const getBusinessUserPosts = async (id: string) => {
  // get email and lookup all the available applications
  try {
    const result = await axios.post(
      `https://getpostscount-gi2cautoja-rj.a.run.app`,
      { user: id }
    );

    return result.data.posts;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
  }
};

const createBusinessUser = async (user: string) => {
  try {
    const result = await axios.post(
      `https://addbusinessuser-gi2cautoja-rj.a.run.app`,
      { user }
    );

    if (result.data.message === "Operation successful") {
      console.log("Business user created successfully");
      return true;
    } else {
      console.error("Failed to create business user:", result.data.message);
      return false;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return false;
  }
};

const getBusinessUsersByCompanyId = async (
  companyId: string
): Promise<BusinessUserInt[]> => {
  const result = await axios.get(
    `https://getbusinessusersbycompanyid-gi2cautoja-rj.a.run.app?companyId=${companyId}`
  );

  return result.data.result as BusinessUserInt[];
};

export {
  getBusinessUserPosts,
  createBusinessUser,
  getBusinessUsersByCompanyId,
};
