import { useState, useCallback } from "react";
import "../../assets/styles/dashboard/positions.css";
import { JobInt } from "../../typescript/interfaces/JobInterface";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IosShareIcon from "@mui/icons-material/IosShare";

import { CompanyInt } from "../../typescript/interfaces/CompanyInt";
import AssignedRecruitersCard from "./tools/AssignedRecruitersCard";

interface JobPostingRowProps {
  job: JobInt;
  companyData: CompanyInt;
  getPositions: () => Promise<void>;
  refreshDashboard: () => void;
  setShowEraseJobAlert: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
  activePopupId: string | null;
  setActivePopupId: (id: string | null) => void;
}

function JobPostingRow(props: JobPostingRowProps) {
  const [copyMessage, setCopyMessage] = useState<string | null>(null);
  const handleMouseLeave = () => {
    props.setActivePopupId(null);
  };

  const handleClick = () => {
    props.onEdit();
  };

  const handleRowClick = (event: React.MouseEvent) => {
    props.onClick();
  };

  const parseDate = (date: Date) => {
    const dateObject = new Date(date);
    const today = new Date();
    const timeDifference = today.getTime() - dateObject.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    const formattedDate = dateObject.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return (
      <>
        <div>{formattedDate}</div>({daysDifference} días)
      </>
    );
  };

  const handleCopy = useCallback(() => {
    const jobUrl = `${window.location.origin}/job-des/job-apply?id=${props.job._id}`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(jobUrl)
        .then(() => {
          setCopyMessage("Enlace Copiado");
          setTimeout(() => setCopyMessage(null), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          setCopyMessage("Error al copiar");
          setTimeout(() => setCopyMessage(null), 2000);
        });
    } else {
      // Fallback for browsers that don't support clipboard API
      const textArea = document.createElement("textarea");
      textArea.value = jobUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        setCopyMessage("Copiado");
        setTimeout(() => setCopyMessage(null), 2000);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
        setCopyMessage("Error al copiar");
        setTimeout(() => setCopyMessage(null), 2000);
      }
      document.body.removeChild(textArea);
    }
  }, [props.job._id]);

  return (
    <tr className="border-b border-gray-200 hover:bg-gray-50 cursor-pointer">
      <td className="py-2 px-4" onClick={handleRowClick}>
        {props.job.title}
      </td>
      <td className="py-2 px-4 hidden sm:table-cell" onClick={handleRowClick}>
        {props.job.location.city && props.job.location.country
          ? `${props.job.location.city}, ${props.job.location.country}`
          : ""}
      </td>
      <td className="py-2 px-4" onClick={handleRowClick}>
        {props.job.applicants || 0}
      </td>
      <td className="py-2 px-4 hidden sm:table-cell" onClick={handleRowClick}>
        {parseDate(props.job.datePosted)}
      </td>
      <td
        className="py-2 px-4"
        style={{ color: props.job.status ? "var(--laburo-green)" : "gray" }}
        onClick={handleRowClick}
      >
        {props.job.status ? "activo" : "pausado"}
      </td>
      <td
        className="py-2 px-4"
        onClick={handleRowClick}
        onMouseLeave={handleMouseLeave}
      >
        <AssignedRecruitersCard
          assignedUsers={props.job.assignedTo || []}
          isActive={props.activePopupId === props.job._id}
          onPopupToggle={(isOpen) =>
            props.setActivePopupId(isOpen ? (props.job._id as string) : null)
          }
        />
      </td>
      <td className="py-2 px-4 flex justify-center items-center gap-2 acciones">
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleCopy();
          }}
          className="p-2 rounded-full hover:bg-gray-200 cursor-pointer"
          aria-label="Copy Link"
          style={{ position: "relative" }}
        >
          <IosShareIcon style={{ fontSize: "20px" }} />
          {copyMessage && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "white",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "12px",
                whiteSpace: "nowrap",
                zIndex: 1000,
              }}
            >
              {copyMessage}
            </div>
          )}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
          className="p-2 rounded-full hover:bg-gray-200 cursor-pointer"
          aria-label="Edit Job"
        >
          <EditIcon style={{ fontSize: "20px" }} />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            props.onDelete();
          }}
          className="p-2 rounded-full hover:bg-gray-200 cursor-pointer"
          aria-label="Delete Job"
        >
          <DeleteOutlineIcon style={{ fontSize: "20px" }} />
        </div>
      </td>
    </tr>
  );
}

export default JobPostingRow;
