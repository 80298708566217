import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ResumeUploader from "../resume/ResumeUploader";
import {
  ApplicantInt,
  Education,
  WorkExperience,
} from "../../typescript/interfaces/AppInterface";
import LoadingWidget from "../widgets/LoadingWidget";
import TextEditor2 from "../text-editor/TextEditor2";
import JobExperienceSection from "../job-apply/JobExperienceSection";
import EducationSection from "../job-apply/EducationSection";
import { useNotification } from "../context/NotificationContext";
import { useApplicants } from "../routing/ApplicantContext";
import { uploadResumeToFirebase } from "../../utils/firebase/firebaseStorageUtils";
import { useJobPositions } from "../routing/JobPositionsContext";

import {
  addApplicant,
  deleteApplicant,
  parseResume,
} from "../../utils/applicantFunctions";

interface ApplicantEditPageProps {
  applicant: ApplicantInt;
  onClose: () => void;
}

function ApplicantEditPage({ applicant, onClose }: ApplicantEditPageProps) {
  const { showNotification } = useNotification();
  const { fetchCompanyApplicants, fetchJobApplicants } = useApplicants();
  const [loading, setLoading] = useState(false);
  const [parsingResume, setParsingResume] = useState(false);
  const [uploadStatus] = useState(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { fetchPositions } = useJobPositions();
  // Form states pre-populated with applicant data
  const [name, setName] = useState(applicant.name);
  const [email, setEmail] = useState(applicant.email);
  const [phone, setPhone] = useState(applicant.number);
  const [resume, setResume] = useState<File>();
  const [description, setDescription] = useState(applicant.description);
  const [educationList, setEducationList] = useState<Education[]>(
    applicant.educationList || []
  );
  const [experienceList, setExperienceList] = useState<WorkExperience[]>(
    applicant.experienceList || []
  );
  const [yearsOfExperience, setYearsOfExperience] = useState<string>(
    applicant.workExperienceYears || "menos_1"
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      let resumeUrlCreated = applicant.resumeUrl;

      // Only upload new resume if one was selected
      if (resume) {
        resumeUrlCreated = await uploadResumeToFirebase(
          resume,
          applicant.jobId,
          name
        );
      }

      const apiData = {
        ...applicant,
        name,
        email,
        number: phone,
        description,
        resumeUrl: resumeUrlCreated,
        educationList,
        experienceList,
        workExperienceYears: yearsOfExperience,
        fileType: resume?.type || applicant.fileType,
      };
      console.log(apiData);

      const result = await addApplicant(apiData); // You'll need to create this function

      if (result.success) {
        showNotification("Candidato actualizado exitosamente", "success");
        await fetchCompanyApplicants(
          applicant.companyId as string,
          1,
          20,
          "",
          {}
        );
        if (applicant.jobId) {
          await fetchJobApplicants(applicant.jobId);
        }
        onClose();
      } else {
        showNotification(result.message, "error");
      }
    } catch (error) {
      console.error("Error updating:", error);
      showNotification(
        error instanceof Error
          ? error.message
          : "Error al actualizar candidato",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };
  const handleResumeUpload = async (file: File | undefined) => {
    setResume(file);
    if (!file) return;

    try {
      setParsingResume(true);
      const parsedData = await parseResume(file);

      setName(parsedData.nombre);
      setEmail(parsedData.email);
      setPhone(parsedData.telefono);
      setEducationList(parsedData.educacion as Education[]);
      setExperienceList(parsedData.experiencia_laboral as WorkExperience[]);
      setYearsOfExperience(parsedData.rango_años_experiencia);
    } catch (error) {
      console.error("Error parsing resume:", error);
    } finally {
      setParsingResume(false);
    }
  };

  // Add this function to check if any changes were made
  const hasChanges = (): boolean => {
    return (
      name !== applicant.name ||
      email !== applicant.email ||
      phone !== applicant.number ||
      description !== applicant.description ||
      resume !== undefined || // If a new resume is selected
      JSON.stringify(educationList) !==
        JSON.stringify(applicant.educationList || []) ||
      JSON.stringify(experienceList) !==
        JSON.stringify(applicant.experienceList || []) ||
      yearsOfExperience !== (applicant.workExperienceYears || "menos_1")
    );
  };

  const handleDelete = async () => {
    setShowDeleteConfirm(false);
    setIsDeleting(true);
    try {
      // Add your delete API call here
      // const result = await deleteApplicant(applicant.id);
      await deleteApplicant(
        applicant._id as string,
        applicant.resumeUrl as string
      );

      showNotification("Candidato eliminado exitosamente", "success");
      await fetchCompanyApplicants(
        applicant.companyId as string,
        1,
        20,
        "",
        {}
      );
      fetchPositions({}, 1, 10);
      if (applicant.jobId) {
        await fetchJobApplicants(applicant.jobId);
      }
      onClose();
    } catch (error) {
      console.error("Error deleting:", error);
      showNotification(
        error instanceof Error ? error.message : "Error al eliminar candidato",
        "error"
      );
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="bg-white overflow-y-auto w-full flex justify-center">
      {isDeleting && (
        <div className="fixed inset-0 bg-white bg-opacity-50 flex items-center justify-center z-[102]">
          <LoadingWidget loading={true} />
        </div>
      )}
      <div className="bg-white w-full ">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-semibold text-gray-800">
            Editar Candidato
          </h2>
          <div className="flex gap-2">
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="p-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-full transition-colors"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
            <IconButton
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="items-center justify-center flex ">
          <div className="!p-4 sm:!p-6 w-full max-w-3xl">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="w100 postjob-gray-container p-6 bg-gray-50 rounded-lg">
                <ResumeUploader
                  setResume={handleResumeUpload}
                  status={uploadStatus}
                  loading={parsingResume}
                  selectedResume={true}
                />

                <div className="space-y-4 mt-6">
                  <div>
                    <label className="block text-lg font-medium mb-2 text-gray-700">
                      Nombre
                    </label>
                    <div className="search-pill">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="search-pill-input w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-lg font-medium mb-2 text-gray-700">
                      Correo
                    </label>
                    <div className="search-pill">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="search-pill-input w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-lg font-medium mb-2 text-gray-700">
                      Teléfono
                    </label>
                    <div className="search-pill">
                      <input
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="search-pill-input w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <TextEditor2
                      editorName="Descripción"
                      htmlValue={description}
                      setHTMLValue={(value) => setDescription(value)}
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                <JobExperienceSection
                  JobExperienceList={experienceList}
                  handleJobExperienceChange={(index, field, value) => {
                    const updatedList = [...experienceList];
                    updatedList[index] = {
                      ...updatedList[index],
                      [field]: value,
                    };
                    setExperienceList(updatedList);
                  }}
                  handleJobExperienceDelete={(index) => {
                    setExperienceList(
                      experienceList.filter((_, i) => i !== index)
                    );
                  }}
                  addExperience={() =>
                    setExperienceList([...experienceList, {} as WorkExperience])
                  }
                  yearsOfExperience={yearsOfExperience}
                  setYearsOfExperience={setYearsOfExperience}
                />

                <EducationSection
                  educationList={educationList}
                  handleEducationChange={(index, field, value) => {
                    const updatedList = [...educationList];
                    updatedList[index] = {
                      ...updatedList[index],
                      [field]: value,
                    };
                    setEducationList(updatedList);
                  }}
                  handleEducationDelete={(index) => {
                    setEducationList(
                      educationList.filter((_, i) => i !== index)
                    );
                  }}
                  addEducation={() =>
                    setEducationList([...educationList, {} as Education])
                  }
                />
              </div>

              <div className="flex justify-center pt-6">
                {loading ? (
                  <LoadingWidget loading={loading} />
                ) : (
                  <button
                    type="submit"
                    className={`w-full max-w-[500px] button-hover text-white px-8 py-3 rounded-lg font-medium transition-colors duration-200 ${
                      hasChanges()
                        ? "bg-laburo-green hover:bg-laburo-green-dark"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                    disabled={loading || !hasChanges()}
                  >
                    Actualizar Candidato
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>

        {showDeleteConfirm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[101]">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full">
              <h3 className="text-lg font-medium text-gray-900">
                Confirmar eliminación
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                ¿Estás seguro de que deseas eliminar este candidato? Esta acción
                no se puede deshacer.
              </p>
              <div className="mt-4 flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
                >
                  {isDeleting ? "Eliminando..." : "Eliminar"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ApplicantEditPage;
