import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deductPostFromCompany } from "../../utils/companyFunctions";

import LoadingWidget from "../widgets/LoadingWidget";

import { JobInt } from "../../typescript/interfaces/JobInterface";

import { BusinessUserInt } from "../../typescript/interfaces/BusinessUserInt";
import { useJobPositions } from "../routing/JobPositionsContext";
interface PostJobSignedInProps {
  job: JobInt;
  businessUser: BusinessUserInt;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

function PostJobSignedIn(props: PostJobSignedInProps) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { refreshPositions } = useJobPositions();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log(props.job);
    event.preventDefault();
    setIsLoading(true);
    try {
      const result = await deductPostFromCompany(
        props.businessUser.companyId as string,
        props.job
      );

      if (
        result ===
        "Post deducted, activated, and confirmation sent successfully"
      ) {
        console.log("Navigating to success page"); // Add logging here
        // refresh the jobs on dashboard
        refreshPositions();

        navigate("/dashboard?success=true");
      } else {
        console.log("Navigating to failure page"); // Add logging here
        navigate("/dashboard?success=false");
        throw new Error("Failed to deduct post");
      }
    } catch (error) {
      console.error("Error posting job:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingWidget loading={isLoading} />
      ) : (
        <form className="flx flx-center mt-25 " onSubmit={handleSubmit}>
          <button
            type="submit"
            className="postjob-createjob-button"
            style={{ color: "white" }}
          >
            Publicar
          </button>
        </form>
      )}
    </React.Fragment>
  );
}

export default PostJobSignedIn;
