import React, { useEffect, useRef, useState } from "react";
import {
  applicantStateOption,
  assignedTo,
  JobInt,
} from "../../typescript/interfaces/JobInterface";
import { Switch } from "@mui/material";
import { updateDbStatusDescription } from "../../utils/jobsUtils";
import { useNotification } from "../context/NotificationContext";
import AssignJobTo from "../create-job/AssignJobTo";
import Close from "@mui/icons-material/Close";
import ValidateMap from "../create-job/ValidateMap";
import TextEditor2 from "../text-editor/TextEditor2";
import ApplicantOptionSelector from "../create-job/ApplicantOptionSelector";
interface JobEditPopUpProps {
  setJobPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  job: JobInt;
  getPositions: () => Promise<void>;
}
function JobEditPopUp(props: JobEditPopUpProps) {
  const [loading, setIsLoading] = useState(false);
  const { showNotification } = useNotification();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [selectedApplicantOptions, setSelectedApplicantOptions] = useState<
    applicantStateOption[]
  >(props.job?.applicantStateOptions || []);
  const [assignedUsers, setAssignedUsers] = useState<assignedTo[]>(
    props.job?.assignedTo || []
  );
  const [htmlValue, setHTMLValue] = useState("");
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        props.setJobPopUp(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  const scrollToTop = () => {
    if (popupRef.current) {
      popupRef.current.scrollTop = 0;
    }
  };

  const closeOnClick = () => {
    props.setJobPopUp(false);
  };

  // Updated hasChanges function with more robust checks
  const hasChanges = (): boolean => {
    return (
      isChecked !== props.job.status ||
      // Trim whitespace and normalize line endings for more accurate comparison
      htmlValue.trim() !== props.job.description.trim() ||
      // Deep comparison of arrays
      !areArraysEqual(
        selectedApplicantOptions,
        props.job.applicantStateOptions
      ) ||
      !areArraysEqual(assignedUsers, props.job.assignedTo || [])
    );
  };

  // Helper function for deep array comparison
  const areArraysEqual = (
    arr1: applicantStateOption[] | assignedTo[],
    arr2: applicantStateOption[] | assignedTo[]
  ): boolean => {
    if (arr1.length !== arr2.length) return false;
    return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
  };

  // Update handleSubmit to check for changes
  const handleSubmit = async () => {
    if (!hasChanges()) {
      showNotification("No hay cambios para guardar", "error");
      return;
    }

    setIsLoading(true);
    if (props.job) {
      const updatedPost = {
        ...props.job,
        description: htmlValue,
        status: isChecked,
        applicantStateOptions: selectedApplicantOptions,
        assignedTo: assignedUsers,
      };

      try {
        await updateDbStatusDescription(updatedPost, true);
        props.getPositions();
        setIsLoading(false);
        scrollToTop();
        showNotification(
          "¡Tus cambios han sido guardados con éxito!",
          "success"
        );
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        showNotification("No se pudieron guardar tus cambios", "error");
      }
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    const getJobData = async () => {
      setIsLoading(true);
      // when result is null put ale
      if (props.job) {
        try {
          setIsChecked(props.job.status);
          setHTMLValue(props.job.description);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    };

    getJobData();
  }, [props.job]);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
      <div
        ref={popupRef}
        className="bg-white rounded-lg p-6 max-w-2xl w-full h-[90vh] flex flex-col"
      >
        {/* Header Section */}
        <div className="flex justify-between items-start border-b border-gray-200 pb-4">
          <div className="space-y-2">
            <h2 className="text-2xl font-semibold text-gray-900">
              {props.job.title}
            </h2>
            <div className="space-y-1">
              <p className="text-lg text-gray-700">{props.job.company}</p>
              <p className="text-base text-gray-600">
                {props.job.location.city + ", " + props.job.location.country}
              </p>
            </div>
          </div>
          <button
            onClick={closeOnClick}
            className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Cerrar"
          >
            <Close style={{ fontSize: "24px" }} />
          </button>
        </div>

        {/* Main Content - Scrollable */}
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent pr-2 pt-6">
          {/* Job Details */}
          <div className="space-y-6">
            {/* Salary */}
            {props.job.salaryRange[0] && (
              <div className="p-4 bg-gray-50 rounded-lg">
                {props?.job.salaryRange[1] && (
                  <div className="text-base text-gray-600">
                    {"$" +
                      props?.job.salaryRange[0] +
                      " - " +
                      "$" +
                      props?.job.salaryRange[1]}
                  </div>
                )}
              </div>
            )}

            {/* Status Toggle */}
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-3">
                <span className="text-base font-medium text-gray-700">
                  Estado:
                </span>
                <div className="flex items-center gap-2">
                  <span className="text-base text-gray-600">Pausa</span>
                  <Switch
                    {...label}
                    color="primary"
                    onChange={handleChange}
                    checked={isChecked}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#15BC7F",
                        "&:hover": {
                          backgroundColor: "rgba(21, 188, 127, 0.08)",
                        },
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "#15BC7F",
                        },
                      "& .MuiSwitch-track": {
                        backgroundColor: "#f50057",
                      },
                    }}
                  />
                  <span className="text-base text-gray-600">Activo</span>
                </div>
              </div>
            </div>

            {/* Map Results */}
            {props.job.mapResults && (
              <div className="p-4 bg-gray-50 rounded-lg">
                <ValidateMap mapResults={props.job.mapResults} />
              </div>
            )}

            {/* assigedn to  */}
            <div className="p-4 bg-gray-50 rounded-lg">
              <AssignJobTo
                assignedUsers={assignedUsers}
                setAssignedUsers={setAssignedUsers}
                companyId={props.job.companyId}
              />
            </div>
            {/* Applicant Options */}
            <div className="bg-gray-50 rounded-lg">
              <ApplicantOptionSelector
                setSelectedApplicantOptions={setSelectedApplicantOptions}
                selectedApplicantOptions={selectedApplicantOptions}
                backgroundColor="#f9fafb"
              />
            </div>

            {/* Text Editor */}
            <div className="p-4 bg-gray-50 rounded-lg">
              <TextEditor2
                editorName="Descripción"
                htmlValue={htmlValue}
                setHTMLValue={setHTMLValue}
              />
            </div>
          </div>
        </div>

        {/* Footer with Submit Button */}
        <div className="flex justify-end gap-3 pt-4 mt-4 border-t">
          <button
            onClick={closeOnClick}
            className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancelar
          </button>
          {loading ? (
            <button className="w-28 px-4 py-2 text-white bg-laburo-green rounded-md">
              Guardando...
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              disabled={!hasChanges()}
              className={`w-28 px-4 py-2 text-white rounded-md ${
                hasChanges()
                  ? "bg-laburo-green hover:bg-emerald-600"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
            >
              Guardar
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobEditPopUp;
