import React from "react";
import { assignedTo } from "../../typescript/interfaces/JobInterface";
import PersonIcon from "@mui/icons-material/Person";

interface AssignedUsersDisplayProps {
  assignedUsers: assignedTo[];
  backgroundColor?: string;
  showTitle?: boolean;
}

function AssignedUsersDisplay({
  assignedUsers,
  backgroundColor,
  showTitle = false,
}: AssignedUsersDisplayProps) {
  return (
    <div
      className=""
      style={{
        borderRadius: "10px",
        marginTop: "0px",
      }}
    >
      {showTitle && (
        <div
          className="flex items-center gap-2"
          style={{
            fontSize: "18px",
            fontWeight: "",
            marginBottom: "10px",
          }}
        >
          Reclutadores Asignados
          <span className="text-sm text-gray-500">
            (Encargados de la publicación)
          </span>
        </div>
      )}

      <div className="space-y-2">
        {assignedUsers.map((user) => (
          <div
            key={user.businessUserId}
            className="flex items-center gap-4 bg-white p-4 rounded-md shadow-sm  "
          >
            <div className="flex items-center gap-2">
              {user.profileImage ? (
                <img
                  src={user.profileImage}
                  alt={`${user.firstName} ${user.lastName}`}
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white text-sm font-medium">
                  {user.firstName && user.lastName ? (
                    user.firstName.charAt(0) + user.lastName.charAt(0)
                  ) : (
                    <PersonIcon style={{ fontSize: "18px" }} />
                  )}
                </div>
              )}
              <span className="font-medium">
                {user.firstName} {user.lastName}
              </span>
              {user.role && (
                <span className="text-gray-500 text-sm">({user.role})</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AssignedUsersDisplay;
