import React, { useState, useEffect } from "react";
import { assignedTo } from "../../typescript/interfaces/JobInterface";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getBusinessUsersByCompanyId } from "../../utils/businessUserFunctions";
import { BusinessUserInt } from "../../typescript/interfaces/BusinessUserInt";
import PersonIcon from "@mui/icons-material/Person";
import AssignedUsersDisplay from "./AssignedUsersDisplay";

// add loading applicants

interface AssignJobToProps {
  setAssignedUsers: React.Dispatch<React.SetStateAction<assignedTo[]>>;
  assignedUsers: assignedTo[];
  companyId: string;
  backgroundColor?: string;
  readOnly?: boolean;
}

function AssignJobTo({
  setAssignedUsers,
  assignedUsers,
  companyId,
  backgroundColor,
  readOnly,
}: AssignJobToProps) {
  const [availableUsers, setAvailableUsers] = useState<BusinessUserInt[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [loading, setLoading] = useState(true);

  // Fetch available users from businessUser collection
  useEffect(() => {
    // Mock data for testing
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const users = await getBusinessUsersByCompanyId(companyId);
        if (users) {
          setAvailableUsers(users);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [companyId]);

  const handleAssignUser = (userId: string) => {
    if (!userId) return;

    const selectedUser = availableUsers.find((user) => user._id === userId);
    if (!selectedUser) return;

    const newAssignedUser: assignedTo = {
      businessUserId: selectedUser._id || "",
      businessUserEmail: selectedUser.user || "",
      assignedAt: new Date(),
      role: selectedUser.role,
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
    };

    setAssignedUsers([...assignedUsers, newAssignedUser]);
    setSelectedUserId("");
  };

  const removeAssignedUser = (userId: string) => {
    const updatedUsers = assignedUsers.filter(
      (user) => user.businessUserId !== userId
    );
    setAssignedUsers(updatedUsers);
  };

  // If readonly, just render the display component
  if (readOnly) {
    return <AssignedUsersDisplay assignedUsers={assignedUsers} />;
  }

  return (
    <div
      className=""
      style={{
        backgroundColor: backgroundColor,
        marginTop: "0px",
      }}
    >
      <div
        className="flex items-center gap-2"
        style={{
          fontSize: "18px",
          fontWeight: "",
          marginBottom: "10px",
        }}
      >
        Reclutadores Asignados
        <span className="text-sm text-gray-500">
          (Encargados de la publicación)
        </span>
      </div>

      {/* Add new assigned user - modified select */}
      <div className="flex gap-2 mb-3">
        <select
          value={selectedUserId}
          onChange={(e) => handleAssignUser(e.target.value)}
          className="px-3 py-2 border rounded-md w-full outline-none"
          style={{
            appearance: "none",
            backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right 8px center",
            backgroundSize: "1em",
            paddingRight: "2.5rem",
          }}
        >
          {loading ? (
            <option value="">Cargando usuarios...</option>
          ) : (
            <option value="">Seleccionar usuario...</option>
          )}

          {availableUsers
            .filter(
              (user) =>
                !assignedUsers.some(
                  (assigned) => assigned.businessUserId === user._id
                )
            )
            .map((user) => (
              <option key={user._id} value={user._id}>
                {user.firstName
                  ? `${user.firstName} ${user.lastName}`
                  : `${user.user}`}{" "}
                {user.role ? `(${user.role})` : ""}
              </option>
            ))}
        </select>
      </div>

      {/* Use the display component for the list, but with delete buttons */}
      <div className="space-y-2">
        {assignedUsers.map((user) => (
          <div
            key={user.businessUserId}
            className="flex items-center gap-4 bg-white p-4 rounded-md shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="flex items-center gap-2">
              {user.profileImage ? (
                <img
                  src={user.profileImage}
                  alt={`${user.firstName} ${user.lastName}`}
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white text-sm font-medium">
                  {user.firstName && user.lastName ? (
                    user.firstName.charAt(0) + user.lastName.charAt(0)
                  ) : (
                    <PersonIcon style={{ fontSize: "18px" }} />
                  )}
                </div>
              )}
              <span className="font-medium">
                {user.firstName
                  ? `${user.firstName} ${user.lastName}`
                  : `${user.businessUserEmail}`}
              </span>
              {user.role && (
                <span className="text-gray-500 text-sm">({user.role})</span>
              )}
            </div>

            <div className="flex items-center gap-2 ml-auto">
              <button
                onClick={() => removeAssignedUser(user.businessUserId)}
                className="text-red-500 hover:text-red-700 transition-colors"
              >
                <DeleteOutlineIcon style={{ fontSize: "20px" }} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AssignJobTo;
