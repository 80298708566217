import React from "react";
import {
  ApplicantActivityInt,
  ApplicantActivityLogInt,
} from "../../../typescript/interfaces/ApplicantActivityInt";
import LoadingWidget from "../../widgets/LoadingWidget";

interface ApplicantHistoryProps {
  applicantActivity: ApplicantActivityInt | null;
  isLoading: boolean;
}

// Log type components
const CommentAddedLog: React.FC<{ log: ApplicantActivityLogInt }> = ({
  log,
}) => (
  <div className="flex items-center gap-2">
    <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center shrink-0">
      <svg
        className="w-4 h-4 text-blue-600"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
        />
      </svg>
    </div>
    <div>
      <span className="font-medium">{log.logAuthor.name}</span> agregó un
      comentario
    </div>
  </div>
);

const MoveApplicantLog: React.FC<{ log: ApplicantActivityLogInt }> = ({
  log,
}) => (
  <div className="flex items-center gap-2">
    <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center shrink-0">
      <svg
        className="w-4 h-4 text-green-600"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 7l5 5m0 0l-5 5m5-5H6"
        />
      </svg>
    </div>
    <div>
      <span className="font-medium">
        {log.logAuthor.type === "USER" ? log.logAuthor.name : "Automatización"}
      </span>{" "}
      movió al candidato de <span className="font-medium">{log.fromState}</span>{" "}
      a <span className="font-medium">{log.toState}</span>
    </div>
  </div>
);

const SendEmailLog: React.FC<{ log: ApplicantActivityLogInt }> = ({ log }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const recipientMap = {
    APPLICANT: "al candidato",
    TEAM: "al equipo",
    BOTH: "al candidato y al equipo",
  };
  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center shrink-0">
          <svg
            className="w-4 h-4 text-purple-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div>
          <span className="font-medium">{log.logAuthor.name}</span> envió un
          email {recipientMap[log.emailRecipient as keyof typeof recipientMap]}
        </div>
      </div>
      <div className="mt-2">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center gap-1 text-sm text-gray-600 hover:text-gray-900"
        >
          <span className="font-medium">{log.emailTemplateName}</span>
          <svg
            className={`w-4 h-4 transition-transform ${
              isExpanded ? "rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        {isExpanded && (
          <div className="mt-2 p-3 bg-white rounded border">
            <div
              className="prose prose-sm max-w-none"
              dangerouslySetInnerHTML={{ __html: log.emailBody as string }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const CreatedLog: React.FC<{ log: ApplicantActivityLogInt }> = ({ log }) => (
  <div className="flex items-center gap-2">
    <div className="w-8 h-8 rounded-full bg-orange-100 flex items-center justify-center shrink-0">
      <svg
        className="w-4 h-4 text-orange-600"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3}
          d="M12 4v16m8-8H4"
        />
      </svg>
    </div>
    <div>
      {log.source === "MANUALLY_ADDED" ? (
        <span className="font-medium">
          {log.logAuthor.name} añadió al candidato
        </span>
      ) : (
        <span className="font-medium">
          Postuló a través de{" "}
          <span className="font-bold laburo-green">Laburo</span>
        </span>
      )}
    </div>
  </div>
);

// Log type mapping
const logComponents: Record<
  string,
  React.FC<{ log: ApplicantActivityLogInt }>
> = {
  COMMENT_ADDED: CommentAddedLog,
  MOVE_APPLICANT: MoveApplicantLog,
  SEND_EMAIL: SendEmailLog,
  CREATED: CreatedLog,
};

function ApplicantHistory({
  applicantActivity,
  isLoading,
}: ApplicantHistoryProps) {
  // Add ref for scrolling
  const logsContainerRef = React.useRef<HTMLDivElement>(null);

  // Add scroll handler
  const scrollToTop = () => {
    if (logsContainerRef.current) {
      logsContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <LoadingWidget small={false} loading={true} />
      </div>
    );
  }

  if (!applicantActivity) {
    return (
      <div className="text-center text-gray-500 py-8">
        <p>No hay actividad registrada</p>
      </div>
    );
  }
  const logs = [...(applicantActivity.logs || [])].reverse();

  if (logs.length === 0) {
    return (
      <div className="text-center text-gray-500 py-8">
        <p>No hay actividad registrada</p>
      </div>
    );
  }

  return (
    <div
      className="space-y-4 overflow-y-auto sidebar-scroll pb-4"
      ref={logsContainerRef}
    >
      <div className="sticky top-0 z-10 flex justify-center pb-2">
        <button
          onClick={scrollToTop}
          className="px-2 py-1 text-xs font-medium bg-blue-100 text-blue-600 rounded-full hover:bg-blue-200 transition-colors"
        >
          Más reciente
        </button>
      </div>
      {logs.map((log) => {
        const LogComponent = logComponents[log.logType];
        if (!LogComponent) return null;

        return (
          <div key={log.logId} className="p-4 bg-gray-50 rounded-lg">
            <LogComponent log={log} />
            <p className="text-sm text-gray-500 mt-2">
              {new Date(log.logDate).toLocaleDateString("es-ES", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default ApplicantHistory;
