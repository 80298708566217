import React, { useState, useEffect, useRef } from "react";
import {
  ApplicantActivityInt,
  ApplicantCommentInt,
} from "../../../typescript/interfaces/ApplicantActivityInt";
import uploadCommentAttachmentImageToFirebase from "../../../utils/ApplicantActivity/uploadCommentAttatchmentImageToFirebase";
import LoadingWidget from "../../widgets/LoadingWidget";
import getApplicantActivity from "../../../utils/ApplicantActivity/getApplicantActivity";
import addApplicantComment from "../../../utils/ApplicantActivity/addApplicantComment";
import { useUser } from "../../routing/UserContext";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ApplicantHistory from "./ApplicantHistory";

interface ApplicantActivitySideBarProps {
  applicantId: string;
  userId: string;
  jobId: string;
  companyId: string;
  closepop: () => void;
}

function ApplicantActivitySideBar({
  applicantId,
  userId,
  jobId,
  companyId,
  closepop,
}: ApplicantActivitySideBarProps) {
  const [comments, setComments] = useState<ApplicantCommentInt[]>([]);
  const [selectedMenu, setSelectedMenu] =
    useState<keyof typeof menuMap>("history");
  const menuMap = {
    history: "Historial",
    comments: "Comentarios",
  };

  const { userData } = useUser();
  const username = userData?.firstName + " " + userData?.lastName;
  const [newComment, setNewComment] = useState<ApplicantCommentInt>({
    commentText: "",
    commentDate: new Date(),
    commentAuthor: {
      name: username,
      profileUrl: "",
    },
  });
  const [attachment, setAttachment] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [applicantActivity, setApplicantActivity] =
    useState<ApplicantActivityInt | null>(null);

  // Ref for the file input
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Add this ref for the comments container
  const commentsContainerRef = useRef<HTMLDivElement>(null);

  // TODO: Replace with your actual fetch function

  useEffect(() => {
    const fetchApplicantActivity = async () => {
      setIsLoading(true);
      try {
        const response = await getApplicantActivity(applicantId);
        if (response.result) {
          setApplicantActivity(response.result);
          setComments(response.result.comments || []);
        }
      } catch (error) {
        console.error("Error fetching applicant activity:", error);
      }
      setIsLoading(false);
    };

    fetchApplicantActivity();
  }, [applicantId]);

  // Add this useEffect to scroll to bottom when comments change
  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [comments]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newComment.commentText.trim()) return;

    setSubmitting(true);
    try {
      // Create a copy of the comment with current date
      const commentToAdd = {
        ...newComment,
        commentDate: new Date(),
      };

      // Optimistically update UI
      const updatedComments = [...comments, commentToAdd];

      // Make API call
      await addApplicantComment(applicantId, commentToAdd, companyId, jobId);
      setComments(updatedComments);
      setApplicantActivity({
        ...applicantActivity,
        logs: [
          ...(applicantActivity?.logs || []),
          {
            logId: crypto.randomUUID(),
            logDate: new Date().toISOString(),
            logAuthor: { name: username, profileUrl: "" },
            logType: "COMMENT_ADDED",
            logText: "Comment added",
          },
        ],
      } as ApplicantActivityInt);

      // Clear form after successful submission
      setNewComment({
        commentText: "",
        commentDate: new Date(),
        commentAuthor: { name: username, profileUrl: "" },
      });
      setAttachment(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
      // Revert optimistic updates if API calls fail
      if (!applicantActivity) {
        setApplicantActivity(null);
        setComments([]);
      } else {
        setComments(comments); // Revert to previous state
      }
      // Show error to user
      alert("Error submitting comment. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        alert("El archivo es demasiado grande. El tamaño máximo es 10MB.");
        return;
      }
      setAttachment(file);
      const attachmentUrl = await uploadCommentAttachmentImageToFirebase(
        file,
        userId,
        jobId,
        applicantId
      );

      setNewComment({
        ...newComment,
        commentAttachment: {
          url:
            "https://storage.googleapis.com/hrbot-e8686.appspot.com/activity/attachments/published/" +
            attachmentUrl.result.name,
          path: "activity/attachments",
          fileName: attachmentUrl.result.name,
          fileNamePreview: file.name,
        },
      });
    }
    setIsUploading(false);
  };

  // Add this utility function inside the component
  const convertUrlsToLinks = (text: string) => {
    // Updated regex that better handles URLs while avoiding potential catastrophic backtracking
    const urlRegex = /(\b(?:https?:\/\/|www\.)[^\s<>[\]{}|\\^]+)/gi;

    if (!text) return "";

    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        try {
          const href = part.startsWith("www.") ? `https://${part}` : part;
          return (
            <a
              key={index}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-laburo-green font-medium hover:text-laburo-green/80 transition-colors duration-200 border-b border-laburo-green/30 hover:border-laburo-green/80 break-words"
            >
              {part}
            </a>
          );
        } catch {
          return part;
        }
      }
      return part;
    });
  };

  // Add this utility function inside the component, before the return statement
  const formatTimeAgo = (date: Date | string) => {
    const now = new Date();
    const commentDate = new Date(date);
    const diffInMinutes = Math.floor(
      (now.getTime() - commentDate.getTime()) / (1000 * 60)
    );
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 10) {
      return commentDate.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }

    if (diffInDays > 0) {
      return `${diffInDays} ${diffInDays === 1 ? "día" : "días"}`;
    }

    if (diffInHours > 0) {
      return `${diffInHours} ${diffInHours === 1 ? "hora" : "horas"}`;
    }

    return diffInMinutes === 0
      ? "ahora"
      : `${diffInMinutes} ${diffInMinutes === 1 ? "minuto" : "minutos"}`;
  };

  return (
    <aside className="w-96 border-l border-gray-200 bg-white p-4 flex flex-col flex-1 min-w-[350px]">
      <div className="absolute top-0 right-0 p-2">
        <IconButton
          onClick={closepop}
          className="text-gray-500 hover:text-gray-700"
        >
          <CloseIcon />
        </IconButton>
      </div>

      {/* Menu */}
      <h2 className="text-xl font-semibold mb-3">{menuMap[selectedMenu]}</h2>
      {/* selector menu */}
      <div className="flex items-center gap-1 mb-6 p-1 bg-gray-100 rounded-lg">
        {Object.keys(menuMap).map((key) => (
          <button
            key={key}
            onClick={() => setSelectedMenu(key as keyof typeof menuMap)}
            className={`
              flex-1 px-4 py-2 rounded-md text-sm font-medium transition-all duration-200
              ${
                selectedMenu === key
                  ? "bg-white text-gray-900 shadow-sm"
                  : "text-gray-600 hover:text-gray-900 hover:bg-gray-50"
              }
            `}
          >
            {menuMap[key as keyof typeof menuMap]}
          </button>
        ))}
      </div>

      {/* History of Applicant Activity */}
      {selectedMenu === "history" && (
        <ApplicantHistory
          applicantActivity={applicantActivity}
          isLoading={isLoading}
        />
      )}
      {/* Comments Container */}
      {selectedMenu === "comments" && (
        <>
          <div
            ref={commentsContainerRef}
            className="flex-1 overflow-y-auto space-y-4 mb-4"
          >
            {isLoading ? (
              <div className="h-full flex items-center justify-center text-center text-gray-500">
                <LoadingWidget small={false} loading={true} />
              </div>
            ) : comments.length === 0 ? (
              <div className="h-full flex items-center justify-center text-center text-gray-500">
                <div>
                  <p className="text-lg font-medium mb-1">
                    ¡No hay comentarios aún!
                  </p>
                  <p className="text-sm">Sé el primero en dejar un mensaje</p>
                </div>
              </div>
            ) : (
              comments.map((comment, index) => (
                <div
                  key={comment.commentId || index}
                  className="bg-gray-50 rounded-lg p-4"
                >
                  <div className="flex items-center gap-3 mb-2">
                    {comment.commentAuthor.profileUrl && (
                      <img
                        src={comment.commentAuthor.profileUrl}
                        alt={comment.commentAuthor.name}
                        className="w-8 h-8 rounded-full"
                      />
                    )}
                    <div>
                      <p className="font-medium">
                        {comment.commentAuthor.name}
                      </p>
                      <p className="text-sm text-gray-500">
                        {formatTimeAgo(comment.commentDate)}
                      </p>
                    </div>
                  </div>
                  <p className="text-gray-700 whitespace-pre-wrap break-words">
                    {convertUrlsToLinks(comment.commentText)}
                  </p>
                  {comment.commentAttachment && (
                    <div className="mt-3 flex items-center">
                      <a
                        href={comment.commentAttachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 max-w-full p-2 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors group"
                      >
                        <div className="flex-shrink-0 w-8 h-8 bg-white rounded-lg flex items-center justify-center">
                          <svg
                            className="w-4 h-4 text-gray-600 group-hover:text-gray-800"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                            />
                          </svg>
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="text-sm font-medium text-gray-700 truncate group-hover:text-gray-900">
                            {comment.commentAttachment.fileNamePreview}
                          </p>
                          <p className="text-xs text-gray-500 group-hover:text-gray-700">
                            Ver archivo
                          </p>
                        </div>
                      </a>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
          {/* Formulario de Comentarios */}
          <form onSubmit={handleSubmit} className="mt-auto">
            <div className="border rounded-lg p-3 bg-white">
              <textarea
                value={newComment.commentText}
                onChange={(e) =>
                  setNewComment({ ...newComment, commentText: e.target.value })
                }
                placeholder="Agregar un comentario..."
                className="w-full min-h-[150px] resize-none border-0 focus:ring-0 p-0 outline-none whitespace-pre-wrap"
              />
              {attachment && (
                <div className="mt-2 mb-3 text-sm text-gray-600">
                  <span className="flex items-center  gap-2">
                    <LoadingWidget small={true} loading={isUploading} />
                    {attachment.name}
                  </span>
                  <button
                    type="button"
                    onClick={() => {
                      setAttachment(null);
                      if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                      }
                    }}
                    className="ml-2 text-red-600 hover:text-red-800"
                  >
                    Eliminar
                  </button>
                </div>
              )}
              <div className="flex items-center justify-between mt-3 pt-3 border-t">
                <label className="cursor-pointer">
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
                    onChange={handleAttachment}
                  />
                  <span className="inline-flex items-center text-gray-600 hover:text-gray-900">
                    <svg
                      className="w-5 h-5 mr-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                      />
                    </svg>
                    Adjuntar archivo
                  </span>
                </label>
                <button
                  type="submit"
                  disabled={!newComment.commentText.trim() || isUploading}
                  className="px-4 py-2 h-10 flex items-center gap-2 bg-laburo-green text-white rounded-md hover:bg-laburo-green/80 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {submitting ? (
                    <LoadingWidget small={true} loading={true} />
                  ) : (
                    "Enviar"
                  )}
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </aside>
  );
}

export default ApplicantActivitySideBar;

// todo : add the attachment to the comment if available
