import axios from "axios";
import { ApplicantCommentInt } from "../../typescript/interfaces/ApplicantActivityInt";

const addApplicantComment = async (
  applicantId: string,
  comment: ApplicantCommentInt,
  companyId: string,
  jobId: string
) => {
  try {
    const response = await axios.post(
      "https://addapplicantcomment-gi2cautoja-rj.a.run.app",
      {
        applicantId: applicantId,
        commentData: comment,
        companyId: companyId,
        jobId: jobId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding applicant comment:", error);
    throw error;
  }
};

export default addApplicantComment;
