import React, { useState, useEffect, useRef } from "react";
import { assignedTo } from "../../../typescript/interfaces/JobInterface";
import PersonIcon from "@mui/icons-material/Person";

interface AssignedRecruitersCardProps {
  assignedUsers: assignedTo[];
  isActive: boolean;
  onPopupToggle: (isOpen: boolean) => void;
}

function AssignedRecruitersCard({
  assignedUsers,
  isActive,
  onPopupToggle,
}: AssignedRecruitersCardProps) {
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  // Add click outside and scroll handler
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        popupRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onPopupToggle(false);
      }
    }

    function handleScroll() {
      if (isActive) {
        onPopupToggle(false);
      }
    }

    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll, true); // true for capture phase
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [isActive, onPopupToggle]);

  // Update popup position when it becomes active
  useEffect(() => {
    if (isActive && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setPopupPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [isActive]);

  // Show only first 2 users in preview
  const previewUsers = assignedUsers.slice(0, 2);
  const remainingCount = assignedUsers.length - 2;

  const UserInitials = ({ user }: { user: assignedTo }) => (
    <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white text-sm font-medium">
      {user.firstName && user.lastName ? (
        user.firstName.charAt(0) + user.lastName.charAt(0)
      ) : (
        <PersonIcon style={{ fontSize: "18px" }} />
      )}
    </div>
  );

  return (
    <div
      ref={containerRef}
      className="relative"
      onMouseEnter={() => onPopupToggle(true)}
      onMouseLeave={() => onPopupToggle(false)}
    >
      {/* Preview Display */}
      <div className="flex items-center gap-2">
        {previewUsers.map((user) => (
          <div
            key={user.businessUserId}
            className="flex items-center gap-2"
            title={`${user.firstName} ${user.lastName}`}
          >
            <UserInitials user={user} />
          </div>
        ))}
        {remainingCount > 0 && (
          <div className="text-sm text-gray-500">+{remainingCount}</div>
        )}
      </div>

      {/* Updated Popup */}
      {isActive && (
        <div
          ref={popupRef}
          className="fixed z-[1000] mt-2 bg-white rounded-lg shadow-lg p-2 min-w-[200px]"
          style={{
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left}px`,
          }}
        >
          <div className="space-y-2">
            {assignedUsers.map((user) => (
              <div
                key={user.businessUserId}
                className="flex items-center gap-2 p-1 hover:bg-gray-50 rounded"
              >
                <UserInitials user={user} />
                <div>
                  <div className="font-medium text-sm">
                    {user.firstName} {user.lastName}
                  </div>
                  {user.role && (
                    <div className="text-xs text-gray-500">{user.role}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AssignedRecruitersCard;

// todo :

// take in input of assigned users
// display the user in a nice modern card. it there is more than one, display a preview of them , then when you click or hover it shows a popup with the full list of user cards.
// this is for information only, it does not allow you to assign or unassign users.
