import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useSearchParams } from "react-router-dom";
import {
  generateJobPostingStructuredData,
  getJobById,
} from "../utils/jobsUtils";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";

import LoadingWidget from "../components/widgets/LoadingWidget";
import ResumeUploader from "../components/resume/ResumeUploader";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { JobInt } from "../typescript/interfaces/JobInterface";
import JobPost from "../components/JobPost";
// import { checkApplicantExists, parseResume } from "../utils/applicantFunctions";
import { checkApplicantExists } from "../utils/applicantFunctions";
import { Question } from "../typescript/interfaces/CustomQuestionInt";
import {
  AnswersInt,
  Education,
  WorkExperience,
} from "../typescript/interfaces/AppInterface";
import LocationMapSelector from "../components/create-job/LocationMapSelector";
import { coordResultsProps } from "../typescript/interfaces/MapLocationInt";
import TextEditor2 from "../components/text-editor/TextEditor2";
import TextEditorVisualizer from "../components/text-editor/TextEditorVisualizer";
import JobExperienceSection from "../components/job-apply/JobExperienceSection";
import EducationSection from "../components/job-apply/EducationSection";

function JobApply() {
  const storage = getStorage();
  const [jobId, setJobId] = useState("");
  const [jobName, setJobName] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [searchParams] = useSearchParams();
  const [formAlert, setformAlert] = useState(false);
  const [userAlreadyExistsAlert, setUserAlreadyExistsAlert] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currJob, setCurrJob] = useState<JobInt>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState<File>();
  const alertBox = useRef<HTMLDivElement>(null);
  const [answers, setAnswers] = useState<AnswersInt>({});
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const [noJobMatching, setNoJobMatching] = useState(false);
  const [mapResults, setMapResults] = useState<coordResultsProps>();
  const [educationList, setEducationList] = useState<Education[]>([]);
  const [experienceList, setExperienceList] = useState<WorkExperience[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [parsingResume, setParsingResume] = useState(false);
  const [yearsOfExperience, setYearsOfExperience] = useState<string>("menos_1");

  const handleAnswerChange = (question: string, answer: string) => {
    setAnswers((prev) => ({ ...prev, [question]: answer }));
  };

  const handleScroll = () => {
    if (alertBox.current) {
      // Get the element's current position relative to the top of the viewport
      const elementTop = alertBox.current.getBoundingClientRect().top;

      // Calculate the offset needed to place the element 70px from the top
      const offset = elementTop - 80;

      // Scroll the window by the calculated offset
      window.scrollBy({
        top: offset,
        behavior: "smooth",
      });
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const questions =
      currJob?.questions?.every((question) => {
        return (
          answers[question.question] !== undefined &&
          answers[question.question] !== "" &&
          answers[question.question] !== null
        );
      }) || currJob?.questions === undefined;

    let mapReq =
      (mapResults && mapResults.center.lat && mapResults.center.lng) ||
      currJob?.mapResults === undefined;

    if (resume && name && number && email && questions && mapReq) {
      setformAlert(false);
      setLoading(true);

      try {
        // Check if email is already registered
        const userExists = await checkApplicantExists(jobId, email);
        if (!userExists) {
          let url = ""; // Default to empty string if no file is uploaded

          if (resume instanceof File) {
            // Proceed with the file upload
            const storageRef = ref(
              storage,
              `/files/${new Date().getTime()}__${jobId}_${name}`
            );
            const uploadTask = uploadBytesResumable(storageRef, resume);

            try {
              await new Promise((resolve, reject) => {
                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    const percent =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadStatus(percent);
                  },
                  (err) => reject(err),
                  () => resolve(uploadTask.snapshot.ref)
                );
              });

              url = await getDownloadURL(uploadTask.snapshot.ref);
            } catch (err) {
              console.error("Upload or URL retrieval failed:", err);
              alert(
                "Error al subir el archivo: Por favor asegurese de que el archivo sea un PDF, DOC, o DOCX y que no supere los 4MB."
              );
              setLoading(false);
              return; // Stop further execution on error
            }
          }

          // Prepare data to send, regardless of whether a file was uploaded
          const apiData = {
            to: currJob?.recieveEmail,
            subject: "Aplicación para la posición de " + jobName,
            html: description,
            company: currJob?.company,
            name,
            number,
            email,
            date: new Date(),
            description,
            jobName: jobName,
            resumeUrl: url,
            jobId: jobId,
            answers: answers,
            mapResults,
            fileType: resume?.type,
            educationList,
            experienceList,
            state: currJob?.applicantStateOptions[0].id,
            stateOption: currJob?.applicantStateOptions[0],
            companyId: currJob?.companyId,
            workExperienceYears: yearsOfExperience,
            assignedTo: currJob?.assignedTo,
          };
          const apiUrl = "https://sendmessage-gi2cautoja-rj.a.run.app";

          try {
            await axios.post(apiUrl, apiData);
            navigate("/thank-you");
          } catch (error) {
            console.error("Failed to send application: ", error);
            alert("Error: No se pudo enviar la aplicación: " + error);
          } finally {
            setLoading(false);
          }
        } else {
          setUserAlreadyExistsAlert(true);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        alert("Error al enviar el formulario: " + error);
        setLoading(false);
      }
    } else {
      setformAlert(true); // Show alert if not all fields are filled
      setLoading(false);
    }
  };
  const showEmptyFields = () => {
    // check if the number of answers is the same as the number of questions

    let questionsReq =
      Object.keys(answers)?.length === currJob?.questions?.length ||
      currJob?.questions === undefined;

    let mapReq =
      (mapResults && mapResults.center.lat && mapResults.center.lng) ||
      currJob?.mapResults === undefined;

    let names = [
      "CV",
      "Nombre",
      "Email",
      "Teléfono",
      "Preguntas Adicionales",
      "Ubicación",
    ];
    // let names = ["CV", "Nombre", "Email", "Teléfono"];
    let form = [resume, name, email, number, questionsReq, mapReq];

    // let form = [resume, name, email, number];
    return form.map((val, index) => {
      if (!val) {
        return <div key={index}>{names[index]}</div>;
      }
      return null;
    });
  };

  const handleInvalidEmail = (e: React.FormEvent<HTMLInputElement>) => {
    // Set a custom message in Spanish
    // e.currentTarget.setCustomValidity(
    //   "Por favor introduce una dirección de correo válida."
    // );
  };

  // disable button until the data is populated

  const constHandleMapSubmit = () => {};
  useEffect(() => {
    // const name = searchParams.get("name");
    const fetchJobData = async () => {
      // get the job data from aprams
      const id = searchParams.get("id");
      let ID = searchParams.get("ID");
      try {
        if (id) {
          const jobData = await getJobById(id);
          setJobId(id);
          setCurrJob(jobData);
          setJobName(jobData.title);
          document.title = `${jobData.title} - ${jobData.company}`;
          updateMetaTags(
            jobData.title,
            jobData.description,
            "URL to your image",
            window.location.href
          );
          // Inject structured data
          const script = document.createElement("script");
          script.type = "application/ld+json";
          script.innerHTML = JSON.stringify(
            generateJobPostingStructuredData(jobData)
          );
          document.head.appendChild(script);
        } else if (ID) {
          ID = ID.toLowerCase();
          const jobData = await getJobById(ID);
          setJobId(ID);
          setCurrJob(jobData);
          setJobName(jobData.title);
          const script = document.createElement("script");
          script.type = "application/ld+json";
          script.innerHTML = JSON.stringify(
            generateJobPostingStructuredData(jobData)
          );
          document.head.appendChild(script);
        } else {
          setNoJobMatching(true);
        }
      } catch (err) {
        console.error("Error fetching job data:", err);
        setNoJobMatching(true);
      }
    };

    fetchJobData();
  }, [searchParams]);

  useEffect(() => {
    if (formAlert || userAlreadyExistsAlert) {
      handleScroll();
      setTimeout(() => {
        if (formAlert) {
          setformAlert(false);
        } else {
          setUserAlreadyExistsAlert(false);
        }
      }, 10000);
    }
  }, [formAlert, userAlreadyExistsAlert]);
  const updateMetaTags = (
    title: string,
    description: string,
    imageUrl: string,
    url: string
  ) => {
    const metaTitle = document.getElementById("meta-og-title");
    const metaDescription = document.getElementById("meta-og-description");
    // const metaImage = document.getElementById("meta-og-image");
    const metaUrl = document.getElementById("meta-og-url");

    if (metaTitle) metaTitle.setAttribute("content", title);
    if (metaDescription) metaDescription.setAttribute("content", description);
    // if (metaImage) metaImage.setAttribute("content", imageUrl);
    if (metaUrl) metaUrl.setAttribute("content", url);
  };

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
  };
  const addExperience = () => {
    setExperienceList([...experienceList, {} as WorkExperience]);
  };
  const addEducation = () => {
    setEducationList([...educationList, {} as Education]);
  };
  const handleEducationChange = (
    index: number,
    field: keyof Education,
    value: string | boolean
  ) => {
    const updatedEducation = [...educationList];
    updatedEducation[index] = { ...updatedEducation[index], [field]: value };
    setEducationList(updatedEducation);
    console.log("education list");
    console.log(updatedEducation);
  };
  const handleResumeUpload = async (file: File | undefined) => {
    setResume(file);
    setLoading(true);
    try {
      setParsingResume(true);
      // if (file) {
      //   console.log("parsing resume");
      //   const parsedData = await parseResume(file);
      //   console.log("parsed resume data");
      //   console.log(parsedData);
      //   setEducationList(parsedData.educacion as Education[]);
      //   setExperienceList(parsedData.experiencia_laboral as WorkExperience[]);
      //   setName(parsedData.nombre);
      //   setEmail(parsedData.email);
      //   setNumber(parsedData.telefono);
      //   setYearsOfExperience(parsedData.rango_años_experiencia);
      // }
      console.log("parsed resume data");
    } catch (error) {
      console.error("Error parsing resume:", error);
    } finally {
      setLoading(false);
      setParsingResume(false);
    }
  };
  const handleEducationDelete = (index: number) => {
    const updatedEducation = educationList.filter((_, i) => i !== index);
    setEducationList(updatedEducation);
  };
  function handleJobExperienceChange(
    index: number,
    field: keyof WorkExperience,
    value: string | boolean
  ): void {
    const updatedExperience = [...experienceList];
    updatedExperience[index] = { ...updatedExperience[index], [field]: value };
    setExperienceList(updatedExperience);
  }
  const handleJobExperienceDelete = (index: number) => {
    const updatedExperience = experienceList.filter((_, i) => i !== index);
    setExperienceList(updatedExperience);
  };
  return (
    <div className=" flx-col skip-navbar-margin ">
      <Navbar scrollPast={true} hidePanel={true} hideSignIn={true} />

      <div
        className="flx-col  w100"
        style={{ minHeight: "100vh", alignItems: "center" }}
      >
        {noJobMatching ? (
          <div
            className="flx flx-center flx-col mt-50"
            style={{ fontSize: "20px" }}
          >
            <div>El trabajo que buscas no existe 😔</div>
            <div className=" thank-you-txt-more-jobs  ">
              <div>Sigue explorando más oportunidades haciendo click aquí:</div>

              <div className="w100 flx flx-center mt-25">
                <Link
                  to="/jobs"
                  className="aplicar-btn button-hover"
                  style={{ color: "white" }}
                >
                  Más Trabajos
                </Link>
              </div>
            </div>
          </div>
        ) : currJob && currJob.status === false ? (
          <div
            className="flx flx-center flx-col mt-50"
            style={{ fontSize: "20px" }}
          >
            <div>
              Lo sentimos, esta oferta de trabajo ya no está disponible.
            </div>
            <div className=" thank-you-txt-more-jobs  ">
              <div>Explora más oportunidades haciendo clic aquí:</div>

              <div className="w100 flx flx-center mt-25">
                <Link
                  to="/jobs"
                  className="aplicar-btn button-hover"
                  style={{ color: "white" }}
                >
                  Más Trabajos
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flx flx-col flx-center">
            <div className=" job-des-content-apply  ">
              <div className="mt-25 w100 mb-25">
                {/* link back to job des prior */}

                <Link
                  to={`/jobs`}
                  className="link-style "
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    fontSize: "18px",
                  }}
                >
                  <ArrowBackIcon /> Regresar
                </Link>
              </div>
              <div className="w100 mb-25">
                {currJob ? (
                  <JobPost currJob={currJob} />
                ) : (
                  <div className="w100 flx flx-center">
                    <LoadingWidget loading={true} />
                  </div>
                )}
              </div>

              <div className="mb-50 w100 " style={{ padding: "0px 20px" }}>
                <div className="mb-25 txt-s4"> Descripción: </div>
                {currJob && (
                  // <div
                  //   dangerouslySetInnerHTML={{ __html: currJob?.description }}
                  //   className="sidebar-content-description "
                  //   style={{ maxHeight: "none" }}
                  // ></div>
                  <TextEditorVisualizer htmlContent={currJob.description} />
                )}

                <div
                  className="w100"
                  style={{
                    border: "1px solid rgba(0,0,0,0.2)",
                    marginTop: "25px",
                  }}
                ></div>
              </div>

              {formAlert && (
                <div className="w100 mb-25" ref={alertBox}>
                  <Alert severity="error" style={{ borderRadius: "10px" }}>
                    <div className="">
                      Por favor completa estos campos: {showEmptyFields()}
                    </div>
                  </Alert>
                </div>
              )}
              {userAlreadyExistsAlert && (
                <div className="w100 mb-25" ref={alertBox}>
                  <Alert severity="error" style={{ borderRadius: "10px" }}>
                    <div className="">
                      ¡Ya te has postulado a esta posición! No puedes volver a
                      aplicar 😔
                    </div>
                  </Alert>
                </div>
              )}
              <div
                className="w100 flx flx-col"
                style={{ gap: "30px", height: "100%" }}
              >
                <div
                  className="w100 postjob-gray-container"
                  style={{ paddingTop: "40px", paddingBottom: "40px" }}
                >
                  <ResumeUploader
                    setResume={handleResumeUpload}
                    status={uploadStatus}
                    loading={parsingResume ? true : false}
                  />

                  <div className="w100 mt-25">
                    <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                      {" "}
                      Nombre
                    </div>

                    <div className="search-pill">
                      <input
                        type="text"
                        className="search-pill-input "
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w100">
                    <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                      {" "}
                      Email
                    </div>

                    <div className="search-pill">
                      <input
                        type="email"
                        className="search-pill-input "
                        onInvalid={handleInvalidEmail}
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setEmail(e.target.value);
                          e.target.setCustomValidity("");
                        }}
                      />
                    </div>
                  </div>
                  <div className="w100">
                    <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                      {" "}
                      Numero Celular
                    </div>

                    <div className="search-pill">
                      <input
                        type="text"
                        className="search-pill-input "
                        value={number}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setNumber(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w100">
                    <TextEditor2
                      editorName="Descripción"
                      htmlValue={description}
                      setHTMLValue={handleDescriptionChange}
                    />
                  </div>
                </div>

                <div className="flx flx-col" style={{ gap: "30px" }}>
                  <JobExperienceSection
                    JobExperienceList={experienceList}
                    handleJobExperienceChange={handleJobExperienceChange}
                    handleJobExperienceDelete={handleJobExperienceDelete}
                    addExperience={addExperience}
                    yearsOfExperience={yearsOfExperience}
                    setYearsOfExperience={setYearsOfExperience}
                  />
                  <EducationSection
                    educationList={educationList}
                    handleEducationChange={handleEducationChange}
                    handleEducationDelete={handleEducationDelete}
                    addEducation={addEducation}
                  />
                </div>

                {currJob?.mapResults && (
                  <div
                    className="w100 postjob-gray-container "
                    style={{
                      paddingTop: "30px",
                      paddingBottom: "30px",
                      height: "100%",
                    }}
                  >
                    <div className="mb-25" style={{ fontSize: "24px" }}>
                      Dirección Domicilio
                    </div>

                    <LocationMapSelector
                      setMapResults={setMapResults}
                      onSubmit={constHandleMapSubmit}
                      fixedRadius={true}
                      closerZoom={true}
                    />
                  </div>
                )}
                {currJob?.questions && currJob.questions.length > 0 && (
                  <div
                    className="w100 postjob-gray-container "
                    style={{
                      paddingTop: "30px",
                      paddingBottom: "30px",
                      height: "100%",
                    }}
                  >
                    <div
                      className="w100"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="mb-25" style={{ fontSize: "24px" }}>
                        Preguntas Adicionales
                      </div>
                      {currJob?.questions &&
                        currJob.questions.map(
                          (question: Question, index: number) => (
                            <div
                              key={index}
                              className="mb-25 "
                              style={{ height: "100%" }}
                            >
                              <div
                                style={{
                                  marginBottom: "10px",
                                  fontSize: "18px",
                                }}
                              >
                                {question.question}
                              </div>
                              {question.type === "text" ? (
                                <div
                                  className="search-pill"
                                  style={{ height: "100%" }}
                                >
                                  <textarea
                                    className="search-pill-input text-input-post-jobs"
                                    value={answers[question.question] || ""}
                                    onChange={(e) =>
                                      handleAnswerChange(
                                        question.question,
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      height: "100%",
                                      minHeight: "150px",
                                      resize: "vertical",
                                      padding: "10px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="postjob-custom-questions-container-row-select-wrapper ">
                                  <div
                                    className="postjob-custom-questions-container-row-select "
                                    onClick={() =>
                                      setOpenDropdown(
                                        openDropdown === index ? null : index
                                      )
                                    }
                                  >
                                    {answers[question.question] ||
                                      "Selecciona una opción"}
                                  </div>
                                  {openDropdown === index && (
                                    <div className="postjob-custom-questions-container-row-select-options ">
                                      {question.options?.map(
                                        (option, optionIndex) => (
                                          <div
                                            key={optionIndex}
                                            className="postjob-custom-questions-container-row-select-option"
                                            onClick={() => {
                                              handleAnswerChange(
                                                question.question,
                                                option
                                              );
                                              setOpenDropdown(null);
                                            }}
                                          >
                                            {option}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w100 flx-center flx-col mb-50 mt-25 ">
              {loading ? (
                <LoadingWidget loading={loading} />
              ) : (
                <button
                  type="submit"
                  className="aplicar-btn button-hover"
                  style={{ color: "white" }}
                  disabled={loading}
                >
                  Enviar
                </button>
              )}
            </div>
          </form>
        )}

        <Footer type={2} />
      </div>
    </div>
  );
}

export default JobApply;
