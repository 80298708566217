import axios from "axios";

const uploadCommentAttachmentImageToFirebase = async (
  file: File,
  userId: string,
  jobId: string,
  applicantId: string
) => {
  try {
    // Create FormData instance
    const formData = new FormData();

    // Append all data to FormData
    formData.append("attachment", file);
    formData.append("userId", userId);
    formData.append("jobId", jobId);
    formData.append("applicantId", applicantId);

    const response = await axios.post(
      "https://uploadcommentattachmentimagetofirebase-gi2cautoja-rj.a.run.app",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error uploading comment attachment image to Firebase:",
      error
    );
    throw error;
  }
};

export default uploadCommentAttachmentImageToFirebase;
